<template>
    <div class="modal add_step secondary" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_body">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
                <div class="upload_image m-0">
                    <image-library v-model="form.dashboard_thumb" image-type="dashboard-thumb" :is-inside="true" />
                </div>
                <div class="step_content">
                    <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="new-page-form">
                        <div class="setting_wpr">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Page Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.title }">
                                        <Field autocomplete="off" type="text" name="title" v-model="form.title" placeholder="ex: New Page" rules="required" />
                                    </div>
                                    <ErrorMessage name="title" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Description</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                        <Field name="description" v-model="form.description" label="description" autocomplete="off">
                                            <textarea name="description" autocomplete="off" cols="30" rows="10" placeholder="Description goes here.." v-model="form.description"></textarea>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="description" class="text-danger" />
                                </div>
                            </div>
                            <div class="capsule_elm">
                                <h5>Use Saved Styling?</h5>
                                <label for="saved_styling" class="switch_option capsule_btn border-0">
                                    <input type="checkbox" id="saved_styling" v-model="form.use_default" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="capsule_elm">
                                <h5>Make Private?</h5>
                                <label for="form_private" class="switch_option capsule_btn border-0">
                                    <input type="checkbox" id="form_private" v-model="form.is_private" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </div>
                    </Form>
                    <div class="action_wpr">
                        <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Creating' : 'Create' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))

    export default {
        name: 'New Page',

        data () {
            return {
                form: {
                    title: '',
                    use_default: 0,
                    dashboard_thumb: '',
                    is_private: 0,
                    description:'',
                },
            }
        },

        props: {
            modelValue: Boolean,
            refreshPage: {
                type: Function,
                default: () => {}
            }
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageLibrary,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();
                }
            }
        },

        computed: mapState({
            loader: state => state.pageModule.pageComponentLoader,
        }),

        methods: {
            ...mapActions({
                createPage: 'pageModule/createPage',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm = this;

                const form = vm.$refs['new-page-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.handleCreatePage(form);
                    }
                });
            },

            handleCreatePage (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.createPage(vm.form).then((result) => {
                    if (result) {
                        if (vm.$parent.$parent && vm.$parent.$parent.refreshPage) {
                            vm.$parent.$parent.refreshPage();
                            vm.closeModal();
                        } else {
                            vm.$parent.refreshPage();
                            vm.closeModal();
                        }
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    title: '',
                    use_default: 0,
                    dashboard_thumb: '',
                    is_private: 0,
                    description:'',
                };
            }
        }
    }
</script>

<style scoped>
    .step_content {
        padding: 20px 30px 10px 30px;
        flex: 1;
    }

    .step_content .action_wpr {
        width: 100%;
        padding-top: 5px;
    }

    .close_btn {
        position: absolute;
        right: 15px;
        left: auto;
        top: 15px;
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width: 20px;
    }

    .switch_option h5 {
        text-align: left;
        margin: 0;
    }

    .upload_image.image_create {
        margin-bottom: 0;
    }

    .upload_image.image_create .upload_wpr {
        height: 100%;
    }

    :deep(.upload_image .upload_wpr) {
        height: 100%;
    }
</style>
